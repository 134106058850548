// Normalization
body,
h1,
h2,
h3 {
    margin: 0;
    padding: 0;
}

body {
    margin: 0 auto;
    padding: 5px;
}

a {
    text-decoration: none;
}

// App styles
.k-drawer-container {
    background-color: $background-color;

    .k-drawer {
        box-shadow: 0 0 10px rgba(0,0,0,.2);
        border-right-width: 0 !important;
    }

    &.k-drawer-push .k-drawer {
        position: sticky;
        top: 80px;
        height: calc(100vh - 80px);
    }

    .k-drawer-item {
        user-select: none;
    }
}

.card-container {
    background-color: $white;
    padding: 40px 0;
    text-align: center;

    &.grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 10px;
        row-gap: 1em;
    }

    .card-title {
      font-size: 25px;
      margin-bottom: 20px;
      text-align: center;
    }
    .card-text {
      text-align: center;
      max-width: 300px;
      margin: 20px auto;
    }

    .card-buttons {
        .k-button {
            min-width: 115px;
            text-align: center;
            text-transform: uppercase;
            background-color: $ld-lincoln-dark-gray;
            border-bottom: 2px solid $ld-lincoln-orange;
            margin: 10px 0 2px 0;
        }
      .k-button:hover {
        margin-bottom: 0;
        border-bottom: 4px solid $ld-lincoln-orange;
      }
    }

    .card-ranges {
        .k-daterangepicker {
            .k-textbox-container, .k-floating-label-container {
                padding-top: 0;
                min-width: 105px;
            }

            .k-label {
                display: none;
            }
        }
    }

    .card-component {
        grid-column: 1 / -1;
        overflow: hidden;
      li {
        padding: 10px 0;
      }
    }
    .card-links {
      .k-link {
        margin: 15px auto 17px auto;
        align-items: normal;
        background-color: rgba(0,0,0,0);
        border-color: rgb(0, 0, 238);
        border-style: none;
        box-sizing: content-box;
        text-transform: uppercase;
        cursor: pointer;
        display: block;
        font: inherit;
        height: auto;
        padding: 0;
        perspective-origin: 0 0;
        text-align: start;
        text-decoration: none;
        border-bottom: 2px solid $ld-lincoln-orange;
        text-decoration: none;
        transform-origin: 0 0;
        width: auto;
        -moz-appearance: none;
        -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
        -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
      }
      .k-link:hover {
        margin-bottom: 15px;
        border-bottom: 4px solid $ld-lincoln-orange;
      }


      /* Mozilla uses a pseudo-element to show focus on buttons, */
      /* but anchors are highlighted via the focus pseudo-class. */

      @supports (-moz-appearance:none) { /* Mozilla-only */
        k-link::-moz-focus-inner { /* reset any predefined properties */
          border: none;
          padding: 0;
        }
        k-link:focus { /* add outline to focus pseudo-class */
          outline-style: dotted;
          outline-width: 1px;
        }
      }
    }

}

@media (min-width: 480px) {
    .card-container {
        text-align: initial;
        padding: 30px;

        &.grid {
            grid-template-columns: repeat(2, 1fr);
        }

        .card-title {
            grid-column: 1 / 2;
            grid-row: 1;
        }

        .card-buttons {
            text-align: center;
            grid-column: 1 / -1;
            grid-row: 2;
        }

        .card-ranges {
            text-align: right;
            grid-column: 2 / 3;
            grid-row: 1;
        }
    }

 }

@media (min-width: 768px) {
    .card-container {
        margin: 25px 0 50px 0;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0,0,0,.2);

        &.grid {
            grid-template-columns: repeat(3, 1fr);
        }

        .card-title {

        }

        .card-buttons {
            text-align: center;
            grid-column: 2 / 3;
            grid-row: 1;
        }

        .card-ranges {
            text-align: right;
            grid-column: 3 / 4;
        }

        .card-component {
            grid-column-start: 1;
            grid-column-end: -1;
        }
    }
}

// Header
.header {
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 3;

    .nav-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        color: $black;
    }

    .menu-button {
        flex: 0 0 50px;
        text-align: center;
    }

    .hamburger-icon {
        background-image:  url('./../assets/hamburger-icon.svg');
        background-repeat: no-repeat;
        width: 20px;
        cursor: pointer;
    }

    .title {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 25px;
    }

    .k-avatar {
        display: block;
        width: 40px;
        height: 40px;
        flex-basis: 40px;
        margin: 0 25px;
        box-shadow: 0 0 0px 1px $white;
    }

    .settings {
        display: none;
    }
}

@media (min-width: 480px) {
    .header {
        .title {
            flex-direction: row;
            align-items: center;
        }
        .vl {
            border-left: 1px solid $white;
            height: 25px;
            margin: 6px 15px;
        }
    }
}

@media (min-width: 768px) {
    .header {
        .settings {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            padding-right: 25px;
        }

        .k-dropdown {
            max-width: 90px;
            margin-left: 15px;
        }
    }
}

// privacy footer
.privacy-footer {
//   position: absolute;
  bottom: 0;
}

// Dashboard page
.dashboard-page {
    .card-container:last-of-type {
        padding-top: 0;
    }

    .k-grid-toolbar .k-textbox {
        margin-bottom: 10px;
    }

    .k-grid .k-virtual-content,
    .k-grid .k-virtual-content > div {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .k-badge {
        z-index: 1
    }
    .events-disclaimer {
        font-size: 12px;
        font-style: italic;
    }

    .events_button {
        width: 100%;
        height: 60%;

    }
}

@media (min-width: 480px) {
    .dashboard-page {
        .k-grid-toolbar .k-textbox {
            margin-bottom: 0;
            float: left;
        }
        .k-grid-toolbar .k-button {
            float: right;
        }
    }
}

@media (min-width: 768px) {
    .dashboard-page {
        .card-container:last-of-type {
            padding-top: 30px;
        }
    }
}

// Event Planning page
.event-planning-page {
    .info-label {
        width: 130px;
        display: inline-block;
        font-weight: bold;
        margin: 10;
    }
    .card-container {
        grid-template-columns: repeat(1, 1fr);
    }
    .card-title{
        grid-column: 1 / -1;
    }
    .k-hbox {
        padding: 0 20px;
        background: transparent;
        align-items: center;
    }
    .k-card-title {
        font-size: 15px;
        margin-bottom: -6px;
        text-align: left;
    }
    .k-card-subtitle {
        margin-top: 0;
        text-transform: uppercase;
        font-size: 11px;
    }
    .disabled {
        opacity: 0.5;
    }
}

@media (min-width: 480px) {
    .event-planning-page {
        .card-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (min-width: 768px) {
    .event-planning-page {
        .k-hbox {
            padding: 0;
            border-width: 0;
        }
    }
}

@media (min-width: 960px) {
    .event-planning-page {
        .card-container {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media (min-width: 1360px) {
    .event-planning-page {
        .card-container {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}

// Planning page
.planning-page {
    .card-container {
        grid-template-columns: repeat(1, 1fr);
    }
    .card-title{
        grid-column: 1 / -1;
    }
    .k-hbox {
        padding: 0 20px;
        background: transparent;
        align-items: center;
    }
    .k-card-title {
        font-size: 15px;
        margin-bottom: -6px;
        text-align: left;
    }
    .k-card-subtitle {
        margin-top: 0;
        text-transform: uppercase;
        font-size: 11px;
    }
    .disabled {
        opacity: 0.5;
    }
}

@media (min-width: 480px) {
    .planning-page {
        .card-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (min-width: 768px) {
    .planning-page {
        .k-hbox {
            padding: 0;
            border-width: 0;
        }
    }
}

@media (min-width: 960px) {
    .planning-page {
        .card-container {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media (min-width: 1360px) {
    .planning-page {
        .card-container {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}

// Profile page
.profile-page {
    .card-container {
        text-align: left;
    }

    .k-form .k-form-field {
        display: block;
        padding: 0 15px;
    }

    .k-form-field:first-of-type .k-form-field-wrap {
        margin: auto;
    }

    .k-form-field:first-of-type .k-avatar {
        margin: 0 auto 1rem;
    }

    .k-form.k-form-horizontal .k-label {
        width: 100%;
    }

    hr {
        margin-top: 1rem;
        opacity: 0.2;
    }

    .k-form .k-form-buttons {
        justify-content: center;

        & > * {
            min-width: 140px;
        }
    }
}

@media (min-width: 480px) {
    .profile-page {
        .k-form .k-form-field {
            display: flex;
        }

        .k-form-field:first-of-type .k-avatar {
            margin: 0;
        }

        .k-form.k-form-horizontal .k-label {
            width: 25%;
        }
    }
}

// Resource page
.resource-page {
    .card-container {
        text-align: left;
    }

    hr {
        margin-top: 1rem;
        opacity: 0.2;
    }
}

@media (min-width: 480px) {
    .resource-page {
      .logo {
        margin: auto;
        display: block;
        max-width: 640px;
      }
    }
}

 @media (min-width: 768px) {
    .profile-page {
        .k-form {
            margin: 0 auto;
            padding: 0;
        }
        .k-form .k-form-field {
            padding: 0;
        }
    }
 }


// Info page
.info-page {
    background-color: $info-background;
    color: $white;

    h1, h2, h3, .component-link {
        font-family: Metric,Helvetica,Arial,sans-serif;
    }
    a:not(.k-button) {
        color: $link-color;
    }

    .content {
        padding-top: 40px;
        margin: 0 auto;
        position: relative;
        max-width: 820px;
    }

    .kendoka {
        text-align: center;
        img {
            max-width: 280px;
        }
    }

    .section-1 {
        text-align: center;
        h1 {
            font-size: 48px;
            font-weight: bold;
            color: $react-color;
        }

        h2 {
            font-size: 30px;
            margin-bottom: 53px;
        }

        .button-group {
            margin-bottom: 30px;

            .k-button {
                display: block;
                margin: 10px auto;
                min-width: 145px;
                width: 60%;
            }
        }

        .github-link {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            align-content: center;

            .github-text {
                margin-left: 15px;
            }
        }
    }

    .section-2 {
        text-align: center;
        margin-top: 40px;
        padding: 0 15px;
    }

    .section-3 {
        -webkit-columns: 2;
        column-count: 2;
        margin-top: 40px;
        padding: 0 15px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }

    .package-item {
        display: inline-block;
        margin-top: 20px;
    }

    .package-title a {
        font-size: 17px;
        color: #CCCCCC;
        text-transform: uppercase;
    }

    .component-link {
        padding: 5px 0;

        a {
            font-size: 20px;
        }
    }

    .footer {
        background-image: url('../assets/info-background.svg');
        background-repeat: no-repeat;
        height: 320px;
    }
}

@media (min-width: 480px) {
    .info-page {
        .content {
            padding: 40px 40px 0;
        }
        .kendoka {
            img {
                max-width: 348px;
            }
        }

        .section-1 {
            h1 {
                font-size: 71px;
            }

            h2 {
                font-size: 38px;
            }

            .button-group {
                .k-button {
                    display: inline-block;
                    margin-right: 10px;
                    width: auto;
                }
            }
        }
        .section-3 {
            -webkit-columns: 3;
            column-count: 3;
        }
    }
}
@media (max-width: 480px) {
    .k-form-horizontal .k-form-field-wrap {
        max-width: 100%;
    }

}

@media (min-width: 768px) {
    .info-page {
        .content {
            padding: 120px 40px 0;
        }
        .kendoka {
            position: absolute;
            right: 0;
            top: 2em;
            text-align: left;
        }
        .section-1,
        .section-2 {
            text-align: left;
            padding: 0;
        }
        .section-3 {
            -webkit-columns: 4;
            column-count: 4;
            padding: 0;
        }
        .footer {
            background-size: cover;
        }
    }
}

// Home page
.home-page {
  background-color: $ld-lincoln-light-gray;
  color: $ld-lincoln-gray;
  width: 100%;
  min-height: 66vh;
  padding-bottom: 50px;

  a:not(.k-button) {
    color: $link-color;
  }

  .content {
    padding-top: 40px;
    margin: 0 auto;
    position: relative;
    max-width: 670px;
    text-align: center;

  }
  .logo {
    margin: auto;
    display: block;
    max-width: 270px;
  }
  .home-title {
    margin: auto;
    display: block;
  }
  span {
    color: $ld-lincoln-gray;
  }

    .button-group {
      .k-link {
        margin: 0 20px 32px 20px;
        align-items: normal;
        background-color: rgba(0,0,0,0);
        border-color: rgb(0, 0, 238);
        border-style: none;
        box-sizing: content-box;
        text-transform: uppercase;
        cursor: pointer;
        display: inline;
        font: inherit;
        height: auto;
        padding: 0;
        perspective-origin: 0 0;
        text-align: start;
        text-decoration: none;
        border-bottom: 2px solid $ld-lincoln-orange;
        text-decoration: none;
        transform-origin: 0 0;
        width: auto;
        -moz-appearance: none;
        -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
        -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
      }
      .k-link:hover {
        margin-bottom: 30px;
        border-bottom: 4px solid $ld-lincoln-orange;
      }

      /* Mozilla uses a pseudo-element to show focus on buttons, */
      /* but anchors are highlighted via the focus pseudo-class. */

      @supports (-moz-appearance:none) { /* Mozilla-only */
        k-link::-moz-focus-inner { /* reset any predefined properties */
          border: none;
          padding: 0;
        }
        k-link:focus { /* add outline to focus pseudo-class */
          outline-style: dotted;
          outline-width: 1px;
        }
      }
    }
}

// Guidelines page
.guidelines-page {
  background-color: $white;
  color: $ld-lincoln-gray;
  width: 100%;
  min-height: 66vh;
  padding-bottom: 50px;

  a:not(.k-button) {
    color: $link-color;
  }
  .logo {
    margin: auto;
    display: block;
    max-width: 340px;
  }
  .bold {
    font-family: lincolnmillerbblack;
  }

  .content {
    padding: 40px 10px;
    margin: 0 auto;
    position: relative;
    max-width: 1070px;
    text-align: center;

  }
  span {
    color: $ld-lincoln-gray;
  }
  .link {
    font-family: lincolnmillerbblack;
    text-decoration: underline;
    color: $ld-lincoln-gray;
  }
  .a-list {
    display: block;
    margin: 10px 0;
  }
  a:visited{
    color: $ld-lincoln-gray
  }
}
@media (min-width: 480px) {
  .guidelines-page{
    .logo {
      margin: auto;
      display: block;
      max-width: 640px;
    }
  }
}

@media (min-width: 480px) {
  .home-page {
    .content {
      padding: 40px 40px 0;
    }
    .logo {
      margin: auto;
      display: block;
      max-width: 240px;
    }

    .section-1 {
      h1 {
        font-size: 71px;
      }

      h2 {
        font-size: 38px;
      }

      .button-group {
        .k-button {
          display: inline-block;
          margin-right: 10px;
          width: auto;
        }
      }
    }
    .section-3 {
      -webkit-columns: 3;
      column-count: 3;
    }
  }
}
@media (max-width: 480px) {
  .k-form-horizontal .k-form-field-wrap {
    max-width: 100%;
  }

}

@media (min-width: 768px) {
  .home-page {
    .content {
      padding: 20px 40px 0;
    }
    .section-1,
    .section-2 {
      text-align: left;
      padding: 0;
    }
    .section-3 {
      -webkit-columns: 4;
      column-count: 4;
      padding: 0;
    }
    .footer {
      background-size: cover;
    }
  }
}

.btn-disabled {
    background-color: grey !important;
    border-color: grey !important;
}